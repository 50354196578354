import store from '@state/store'

export default [
    {
        path: '/',
        name: 'home',
        component: () => lazyLoadView(import('@views/dashboards/resume/home')),
        meta: {
            authRequired: true,
            beforeResolve: (to, from, next) => {
                if (store.getters['auth/isBusinessUnit']) {
                    next({ name: 'photoGallery' })
                } else {
                    next()
                }
            },
        },
        props: (route) => ({ user: store.state.auth.currentUser || {} }),
    },
    {
        path: '/login',
        name: 'login',
        component: () => lazyLoadView(import('@views/login')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/confirm-account',
        name: 'confirm-account',
        component: () => lazyLoadView(import('@views/confirm')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forget-password',
        name: 'forget-password',
        component: () => lazyLoadView(import('@views/forgetPassword')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut')
                next({ name: 'login' })
            },
        },
    },
    {
        path: '/dashboard/operational',
        name: 'operational',
        component: () =>
            lazyLoadView(import('@views/dashboards/operational/operational')),
        meta: {
            // resource: 'OPERATIONAL',
            authRequired: true,
        },
    },
    {
        path: '/users',
        name: 'users',
        component: () => lazyLoadView(import('@views/users/usersIndex')),
        meta: {
            resource: 'USER',
            authRequired: true,
        },
    },
    {
        path: '/files',
        name: 'files',
        component: () => lazyLoadView(import('@views/files/filesIndex')),
        meta: {
            resource: 'FILE',
            authRequired: true,
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => lazyLoadView(import('@views/users/userProfile')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/pos',
        name: 'pos',
        component: () => lazyLoadView(import('@views/pos/posIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/posChannel',
        name: 'posChannel',
        component: () =>
            lazyLoadView(import('@views/posChannel/posChannelIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/posCategory',
        name: 'posCategory',
        component: () =>
            lazyLoadView(import('@views/posCategory/posCategoryIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/posChain',
        name: 'posChain',
        component: () => lazyLoadView(import('@views/posChain/posChainIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/posRegional',
        name: 'posRegional',
        component: () =>
            lazyLoadView(import('@views/posRegional/posRegionalIndex')),

        meta: {
            resource: 'POSREGIONAL',
            authRequired: true,
        },
    },
    {
        path: '/posFlag',
        name: 'posFlag',
        component: () => lazyLoadView(import('@views/posFlag/posFlagIndex')),
        meta: {
            resource: 'POSFLAG',
            authRequired: true,
        },
    },
    {
        path: '/posCheck',
        name: 'posCheck',
        component: () => lazyLoadView(import('@views/posCheck/posCheckIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/workschedules',
        name: 'workSchedule',
        component: () =>
            lazyLoadView(import('@views/workSchedule/workScheduleIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/workschedule/:id',
        name: 'workScheduleForUser',
        component: () =>
            lazyLoadView(import('@views/workSchedule/workScheduleForUserIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/workschedule/:id/answer/:workScheduleId/:pointOfSaleId/:date',
        name: 'answerWorkScheduleForUser',
        component: () =>
            lazyLoadView(import('@views/workSchedule/answerWorkScheduleForUserIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/teams',
        name: 'teams',
        component: () => lazyLoadView(import('@views/teams/teamsIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/questionnaireAnswers',
        name: 'questionnaireAnswers',
        component: () =>
            lazyLoadView(
                import('@views/questionnaireAnswers/questionnaireAnswersIndex')
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/workdays',
        name: 'workday',
        component: () => lazyLoadView(import('@views/workday/workdayIndex')),
        meta: {
            resource: 'WORKDAY',
            authRequired: true,
        },
    },
    {
        path: '/workLeaves',
        name: 'workLeave',
        component: () => lazyLoadView(import('@views/workLeaves/workLeavesIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/reports/workdayBreaks',
        name: 'workdayBreaksReport',
        component: () =>
            lazyLoadView(import('@views/reports/workdayBreaks/workdayBreaksIndex')),
        meta: {
            // resource: 'WORKDAYS_BREAKS',
            authRequired: true,
        },
    },
    {
        path: '/reports/points',
        name: 'pointsReport',
        component: () =>
            lazyLoadView(import('@views/reports/points/pointsReportIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/brands',
        name: 'brand',
        component: () => lazyLoadView(import('@views/brands/brandIndex')),
        meta: {
            resource: 'BRAND',
            authRequired: true,
        },
    },
    {
        path: '/importJobs',
        name: 'importJobs',
        component: () =>
            lazyLoadView(import('@views/importJob/importJobIndex')),
        meta: {
            resource: 'IMPORTJOB',
            authRequired: true,
        },
    },
    {
        path: '/dashboard/price',
        name: 'price',
        component: () =>
            lazyLoadView(import('@views/dashboards/price/priceIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/dashboard/share',
        name: 'share',
        component: () =>
            lazyLoadView(import('@views/dashboards/share/shareIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/dashboard/outOfStock',
        name: 'outOfStock',
        component: () =>
            lazyLoadView(
                import('@views/dashboards/outOfStock/outOfStockIndex')
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/dashboard/dueDate',
        name: 'dueDate',
        component: () =>
            lazyLoadView(import('@views/dashboards/dueDate/dueDateIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/dashboard/additionalPoint',
        name: 'additionalPoint',
        component: () =>
            lazyLoadView(
                import('@views/dashboards/additionalPoint/additionalPointIndex')
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/dashboard/photoGallery',
        name: 'photoGallery',
        component: () =>
            lazyLoadView(
                import('@views/dashboards/photoGallery/photoGalleryIndex')
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/dashboard/performance/user',
        name: 'userPerformance',
        component: () =>
            lazyLoadView(
                import('@views/dashboards/performance/user/userPerformanceIndex')
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/dashboard/performance/pos',
        name: 'posPerformance',
        component: () =>
            lazyLoadView(
                import('@views/dashboards/performance/pos/posPerformanceIndex')
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/exportPresentationJobs',
        name: 'exportPresentationJobs',
        component: () =>
            lazyLoadView(
                import(
                    '@views/exportPresentationJob/exportPresentationJobIndex'
                )
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/resumeUser',
        name: 'resumeUser',
        component: () =>
            lazyLoadView(import('@views/resumeUser/resumeUserIndex')),
        meta: {
            resource: 'EXPORTJOB',
            authRequired: true,
        },
    },
    {
        path: '/exportJobs',
        name: 'exportJobs',
        component: () =>
            lazyLoadView(import('@views/exportJob/exportJobIndex')),
        meta: {
            resource: 'EXPORTJOB',
            authRequired: true,
        },
    },
    {
        path: '/operationalProblem',
        name: 'operationalProblem',
        component: () =>
            lazyLoadView(import('@views/operationalProblem/operationalProblemIndex')),
        meta: {
            resource: 'EXPORTJOB',
            authRequired: true,
        },
    },
    {
        path: '/dashboard/trackMap',
        name: 'trackMap',
        component: () => lazyLoadView(import('@views/dashboards/trackMap/trackMapIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/dashboard/trackUser',
        name: 'trackUser',
        component: () => lazyLoadView(import('@views/dashboards/trackUser/trackUserIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/dashboard/workSchedulesMap',
        name: 'workSchedulesMap',
        component: () =>
            lazyLoadView(
                import('@views/dashboards/workSchedulesMap/workSchedulesMapIndex')
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/404',
        name: '404',
        component: require('@views/_404').default,
        // Allows props to be passed to the 404 page through route
        // params, such as `resource` to define what wasn't found.
        props: true,
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    {
        path: '*',
        redirect: '404',
    },
    {
        path: '/business-units',
        name: 'business-units',
        component: () =>
            lazyLoadView(import('@views/businessUnit/businessUnitIndex')),
        meta: {
            resource: 'BUSINESSUNIT',
            authRequired: true,
        },
    },
    {
        path: '/business-units-checks',
        name: 'business-units-checks',
        component: () =>
            lazyLoadView(import('@views/busCheck/businessUnitCheckIndex')),
        meta: {
            resource: 'BUSINESSUNITCHECK',
            authRequired: true,
        },
    },
    {
        path: '/product-categories',
        name: 'product-categories',
        component: () =>
            lazyLoadView(
                import('@views/productCategories/productCategoriesIndex')
            ),
        meta: {
            resource: 'PRODUCTCATEGORY',
            authRequired: true,
        },
    },
    {
        path: '/mixes',
        name: 'mixes',
        component: () => lazyLoadView(import('@views/mixes/mixesIndex')),
        meta: {
            resource: 'MIX',
            authRequired: true,
        },
    },
    {
        path: '/products',
        name: 'products',
        component: () => lazyLoadView(import('@views/products/productsIndex')),
        meta: {
            resource: 'PRODUCT',
            authRequired: true,
        },
    },
    {
        path: '/questions',
        name: 'questions',
        component: () =>
            lazyLoadView(import('@views/questions/questionsIndex')),
        meta: {
            resource: 'QUESTION',
            authRequired: true,
        },
    },
    {
        path: '/questionnaires',
        name: 'questionnaires',
        component: () =>
            lazyLoadView(import('@views/questionnaires/questionnairesIndex')),
        meta: {
            resource: 'QUESTIONNAIRE',
            authRequired: true,
        },
    },
    {
        path: '/roles',
        name: 'roles',
        component: () => lazyLoadView(import('@views/roles/rolesIndex')),
        meta: {
            adminOnly: true,
            authRequired: true,
        },
    },
    {
        path: '/justifications',
        name: 'justifications',
        component: () =>
            lazyLoadView(import('@views/justification/justificationIndex')),
        meta: {
            resource: 'JUSTIFICATION',
            authRequired: true,
        },
    },
    {
        path: '/justifications-types',
        name: 'justifications-types',
        component: () =>
            lazyLoadView(
                import('@views/justificationType/justificationTypeIndex')
            ),
        meta: {
            resource: 'JUSTIFICATIONTYPE',
            authRequired: true,
        },
    },
    {
        path: '/tenant',
        name: 'tenant',
        component: () => lazyLoadView(import('@views/tenant/tenantIndex')),
        meta: {
            superAdminOnly: true,
            authRequired: true,
        },
        props: (route) => ({ user: store.state.auth.currentUser || {} }),
    },
    {
        path: '/intelligence/costOfServing',
        name: 'intelligence/costOfServing',
        component: () => lazyLoadView(import('@views/intelligence/costOfServing/costServingIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/commercial/orderHistory',
        name: 'commercial/orderHistory',
        component: () => lazyLoadView(import('@views/commercial/orderHistory/orderHistoryIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/posIntelligence',
        name: 'posIntelligence',
        component: () => lazyLoadView(import('@views/intelligence/pos/posIndex')),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/businessUnitsIntelligence',
        name: 'businessUnitsIntelligence',
        component: () =>
            lazyLoadView(import('@views/intelligence/businessUnit/businessUnitIndex')),
        meta: {
            resource: 'BUSINESSUNIT',
            authRequired: true,
        },
    },
    {
        path: '/posChainIntelligence',
        name: 'posChainIntelligence',
        component: () => lazyLoadView(import('@views/intelligence/posChain/posChainIndex')),
        meta: {
            authRequired: true,
        },
    },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
    const AsyncHandler = () => ({
        component: AsyncView,
        // A component to use while the component is loading.
        loading: require('@views/_loading').default,
        // Delay before showing the loading component.
        // Default: 200 (milliseconds).
        delay: 200,
        // A fallback component in case the timeout is exceeded
        // when loading the component.
        error: require('@views/_timeout').default,
        // Time before giving up trying to load the component.
        // Default: Infinity (milliseconds).
        timeout: 10000,
    })

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            // Transparently pass any props or children
            // to the view component.
            return h(AsyncHandler, data, children)
        },
    })
}
