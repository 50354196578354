import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
    ...mapState('auth', {
        currentUser: (state) => state.currentUser,
        currentToken: (state) => state.currentToken,
    }),
    ...mapGetters('auth', [
        'loggedIn',
        'tenantId',
        'roleName',
        'isAdmin',
        'isSuperAdmin',
        'isBusinessUnit',
        'isPromotor',
        'userLoggedIn',
        'activeModules',
    ]),
}

export const authMethods = mapActions('auth', [
    'logIn',
    'logOut',
    'resetPassword',
    'getDefaultDashboardRole',
])
