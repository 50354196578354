<script>
export default {
    methods: {
        makeI18nKey(prefix, suffix) {
            return !suffix ? `${prefix}` : `${prefix}.${suffix}`
        },
        getI18n(prefix, suffix) {
            return this.$tc(this.makeI18nKey(prefix, suffix))
        },

        i18nExists(prefix, suffix) {
            return this.$te(this.makeI18nKey(prefix, suffix))
        },

        getI18nModified({ prefix, suffix, modifier }) {
            return this.$tc(this.makeI18nKey(prefix, suffix), modifier)
        },

        getI18nModifiedWithNumber({ prefix, suffix, number }) {
            return this.$tc(this.makeI18nKey(prefix, suffix), number, number)
        },

        getI18nNumberFormat(value, type) {
            return this.$n(value, type)
        },

        getI18nCurrency(value) {
            if (!value || value === undefined || value === null) {
                return this.getI18nNumberFormat(0, 'currency')
            }

            if (typeof value !== 'number') {
                return this.getI18n('ERROR_CODES', 'not_a_number')
            }
            return this.getI18nNumberFormat(value, 'currency')
        },
    },
}
</script>
