export const state = {
    posInfo: {},
}

export const getters = {
    getPosInfo(state) {
        return state.posInfo
    }
}

export const mutations = {
    POS_INFO(state, info) {
        state.posInfo = info
    },
}
export const actions = {
    savePosInfo({ commit, state },  posInfo) {
            commit('POS_INFO', posInfo)
    },

    savePosStateAndCity({ commit, state },  posInfo){
        commit('POS_INFO', posInfo)
    }
}
