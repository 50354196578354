import Vue from 'vue'

Vue.filter('firstToUpper', function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('firstToLower', function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toLowerCase() + value.slice(1)
})

Vue.filter('truncate', function(text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})
