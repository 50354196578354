<script>
import Layout from '@layouts/auth'

export default {
    page: {
        title: '404',
        meta: [{ name: 'description', content: '404' }],
    },
    components: { Layout },
    props: {
        resource: {
            type: String,
            default: '',
        },
    },
    computed: {
        i18nTitle() {
            return this.getI18n('PAGE_NOT_FOUND', 'title')
        },
        i18nDescription() {
            return this.getI18n('PAGE_NOT_FOUND', 'description')
        },
        i18nButton() {
            return this.getI18n('PAGE_NOT_FOUND', 'button')
        },
    },
}
</script>

<template>
    <Layout>
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="text-center w-100 m-auto">
                            <a href="/">
                                <span>
                                    <img
                                        class="img-fluid"
                                        src="@assets/images/logo-sm-old.png"
                                        alt
                                        height="100"
                                    />
                                </span>
                            </a>
                        </div>

                        <div class="text-center">
                            <h3 class="mt-4 text-uppercase font-weight-bold">
                                {{ i18nTitle }}
                            </h3>
                            <p class="text-muted mb-0 mt-3" style="line-height: 20px">
                                {{ i18nDescription }}
                            </p>

                            <router-link
                                v-slot="{ navigate }"
                                to="/"
                                class="btn btn-primary mt-3"
                                custom
                            >
                                <a role="link" @click="navigate">
                                    <i class="fe-home mr-1" />
                                    {{ i18nButton }}
                                </a>
                            </router-link>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
    </Layout>
</template>

<style lang="scss" module>
.title {
    text-align: center;
}
</style>
