<script>
import Layout from '@layouts/main'

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Loading page...',
  },
  name: 'Loading',
  components: { Layout },
}
</script>

<template>
  <Layout>
    <!-- Pre-loader -->
    <div class="preloader">
      <div class="status">
        <div class="spinner">Loading...</div>
      </div>
    </div>
    <!-- End Preloader-->
  </Layout>
</template>
