import 'json.date-extensions'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueInfiniteScroll from 'vue-infinite-scroll'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import VCalendar from 'v-calendar';
import VueTour from 'vue-tour'
import Vuelidate from 'vuelidate'
import KnobControl from 'vue-knob-control'
import VueDraggable from 'vue-draggable'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from 'v-click-outside'
import App from './app'
import router from '@router'
import store from '@state/store'
import ImageUploader from 'vue-image-upload-resize'
import * as GmapVue from 'gmap-vue'
import vSelect from "vue-select";

import { i18n } from './i18n'

import '@components/_globals'
import "@lottiefiles/lottie-player";
import 'vue-select/dist/vue-select.css';

import { abilitiesPlugin } from '@casl/vue'
import ability from '@utils/ability'

// import Axios from 'axios'

import i18nMixin from './mixins/getI18n.vue'

import './filters/text'
import './config/axios-config'

// Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

Vue.use(abilitiesPlugin, ability)

Vue.use(VueRouter)
Vue.use(vco)
Vue.use(ImageUploader)

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'development'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
    // Ensure tests fail when Vue emits an error.
    Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

Vue.use(BootstrapVue)
Vue.use(VueInfiniteScroll)
Vue.use(VueSweetalert2)
Vue.use(VCalendar)
Vue.use(Vuelidate)
Vue.use(VueTour)
Vue.use(KnobControl)
Vue.use(VueDraggable)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GMAP_API_KEY,
        libraries: 'places',
    },
    installComponents: false,
})

Vue.component('Apexchart', VueApexCharts)
Vue.component('v-select', vSelect)
Vue.mixin(i18nMixin)

const app = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
    // Attach the app to the window, which can be useful
    // for manually setting state in Cypress commands
    // such as `cy.logIn()`.
    window.__app__ = app
}
