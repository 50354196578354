<script>
export default {
    components: {},
    created: () => {
        document.body.classList.add('authentication-bg')
        document.body.classList.add('authentication-bg-pattern')
    },
    methods: {
        languageDescription(text) {
            return this.getI18n('LANGUAGES', text)
        },
        changeLocate() {
            this.$i18n.locale = this.$i18n.locale === 'en' ? 'pt-BR' : 'en'
        }
    }
}
</script>

<template>
    <div>
        <div class="account-pages">
            <div class="row justify-content-end">
                <div class="col-md-3">
                    <div class="langs">
                        <img
                            id="lang-pt"
                            src="@assets/images/lang-pt.png"
                            :class="$i18n.locale === 'pt-BR' ? 'active' : ''"
                            alt="lang-pt"
                            @click="changeLocate"
                        />
                        <img
                            id="lang-en"
                            src="@assets/images/lang-en.png"
                            :class="$i18n.locale === 'en' ? 'active' : ''"
                            alt="lang-en"
                            @click="changeLocate"
                        />
                        <!-- <img src="@assets/images/lang-it.png" alt="lang-it" />
                        <img src="@assets/images/lang-fr.png" alt="lang-fr" /> -->

                        <b-tooltip target="lang-pt" triggers="hover">
                            {{ languageDescription('pt-BR') }}
                        </b-tooltip>
                        <b-tooltip target="lang-en" triggers="hover">
                            {{ languageDescription('en') }}
                        </b-tooltip>
                    </div>
                </div>
            </div>
            <div class="container mt-5">
                <slot />
            </div>
        </div>
    </div>
</template>
