import Axios from 'axios'
import store from '@state/store'
import router from '@router'

Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

let isRefreshing = false
let subscribers = []

Axios.interceptors.response.use(
    (response) => {
        return response
    },
    (err) => {
        try {
            const {
                config,
                response: { status },
                response,
            } = err

            const originalRequest = config
            if (status === 401 && originalRequest.url !== '/login') {
                if (!isRefreshing) {
                    isRefreshing = true
                    store
                        .dispatch('auth/validate')
                        .then((success) => {
                            if (success) {
                                isRefreshing = false
                                onRefreshed()
                            }
                            subscribers = []
                        })
                        .catch(() => {
                            router.push({
                                name: 'logout',
                            })
                        })
                }

                return new Promise((resolve) => {
                    subscribeTokenRefresh(() => {
                        originalRequest.headers.Authorization =
                            Axios.defaults.headers.common.Authorization
                        resolve(Axios(originalRequest))
                    })
                })
            }
            return Promise.reject(response)
        } catch (err) {
            store.dispatch('auth/logOut')
            router.push({
                name: 'login',
            })
        }
    }
)

function subscribeTokenRefresh(cb) {
    subscribers.push(cb)
}

function onRefreshed() {
    subscribers.map((cb) => cb())
}

subscribers = []
