import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
// import NProgress from 'nprogress/nprogress'
import store from '@state/store'
import routes from './routes'
import ability from '@utils/ability'

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: 'history',
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    // If this isn't an initial page load...
    if (routeFrom.name !== null) {
        // Start the route progress bar.
        // NProgress.start()
    }

    // Check if auth is required on this route
    // (including nested routes).
    const authRequired = routeTo.matched.some(
        (route) => route.meta.authRequired
    )

    // If auth isn't required for the route, just continue.
    if (!authRequired) return next()

    const adminOnly = routeTo.matched.some((route) => route.meta.adminOnly)
    // If route is for only admins, check if user is an admin
    if (adminOnly) {
        if (!store.getters['auth/isAdmin']) {
            return next({
                name: 'home',
            })
        }
    }

    const superAdminOnly = routeTo.matched.some((route) => route.meta.superAdminOnly)
    // If route is for only super admin, check if user is an super admin
    if (superAdminOnly) {
        if (!store.getters['auth/isSuperAdmin']) {
            return next({
                name: 'home',
            })
        }
    }

    if (store.getters['auth/loggedIn']) {
        // If auth is required and the user is logged in...
        // Validate the local user token...
        return store.dispatch('auth/getPermissions').then((response) => {
            const resource = routeTo.matched.some(
                (route) => route.meta.resource
            )

            if (resource) {
                const canNavigate = routeTo.matched.some((route) => {
                    return ability.can('GET', route.meta.resource)
                })

                if (!canNavigate) {
                    if (store.getters['auth/isSuperAdmin']) {
                        return next({
                            name: 'tenant',
                        })
                    }

                    return next({
                        name: 'home',
                    })
                }
            }

            response ? next() : redirectToLogin()
        })
    }

    // If auth is required and the user is NOT currently logged in,
    // redirect to login.
    redirectToLogin()

    function redirectToLogin() {
        // Pass the original route to the login component
        store.dispatch('auth/logOut')
        next({
            name: 'login',
            query: {
                redirectFrom: routeTo.fullPath,
            },
        })
    }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            if (routeFrom.name === args[0].name) {
                                // Complete the animation of the route progress bar.
                                // NProgress.done()
                            }
                            // Complete the redirect.
                            next(...args)
                            reject(new Error('Redirected'))
                        } else {
                            resolve()
                        }
                    })
                } else {
                    // Otherwise, continue resolving the route.
                    resolve()
                }
            })
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return
    }

    // If we reach this point, continue resolving the route.
    next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
    // Complete the animation of the route progress bar.
    // NProgress.done()
})

export default router
