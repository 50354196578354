<script>
import Layout from '@layouts/main'

export default {
    page: {
        title: 'Page timeout',
        meta: [
            { name: 'description', content: 'The page timed out while loading.' },
        ],
    },
    components: { Layout },
    computed: {
        i18nTimeout() {
            return this.getI18n('ERROR_CODES', 'timeout')
        },
    },
}
</script>

<template>
  <Layout>
    <b-row class="justify-content-center">
        <lottie-player
            class="mt-3"
            src="https://assets5.lottiefiles.com/packages/lf20_yqjrf9tf.json"
            background="transparent"
            speed="1"
            style="width: 400px; height: 400px;"
            autoplay
            loop
        />
    </b-row>
    <b-row class="justify-content-center">
        <label class="text-timeout">
            {{ i18nTimeout }}
        </label>
    </b-row>
  </Layout>
</template>

<style scoped>
.icon-timeout {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 90px;
}

.text-timeout {
    color: #66686D;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.05em;
}
</style>
