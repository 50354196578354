import BaseService from '@src/services/BaseService'
import axios from "axios";

class RolesService extends BaseService {
  constructor() {
    super('roles')
  }

  fetchAll(params) {
    return axios.post(`${this.getUrl()}`, params)
  }

  getRoleByName(name) {
    return axios.post(`${this.getUrl()}`, {
        sort: "alias|asc",
        page: 1,
        per_page: 100,
        search: `name:${name}`,
        orderBy: "alias",
        sortedBy: "asc",
    })
  }

  getPermissions(id, params) {
    return this.axios.post(`${this.getUrl()}/${id}/get-permissions`);
  }

  batchAssignPermissionsToRole(id, params) {
    return this.axios.post(`${this.getUrl()}/${id}/batch-assign`, params);
  }

}

export default new RolesService()
