import axios from 'axios'

import * as storeFile from '@state/store'

export default class BaseService {
    constructor(urlRoute, filterTenant = true) {
        this.urlRoute = urlRoute
        this.axios = axios
        this.filterTenant = filterTenant
    }

    getUrl() {
        return `${this.filterTenant ? `tenant/${storeFile.default.getters['auth/tenantId']}/`: ''}${this.urlRoute}`
    }

    getSorting(params) {
        const { sort } = params

        if (typeof sort === 'string' && sort.length > 0) {
            const orderBy = sort.substring(0, sort.lastIndexOf('|'))
            if (orderBy !== 'undefined') {
                params.orderBy = orderBy
                params.sortedBy = sort.substring(
                    sort.lastIndexOf('|') + 1,
                    sort.length
                )
            }
        }

        return params
    }

    fetchAll(params, apiUrl) {
        return axios.post(`${this.getUrl()}${apiUrl ? `/${apiUrl}` : ''}`, params)
    }

    fetchVuetable(apiUrl, { params } = { params: {} }) {
        const parameters = this.getSorting(params)
        return this.fetchAll(parameters, apiUrl)
    }

    fetchById(id, params) {
        return axios.post(`${this.getUrl()}/${id}`, params)
    }

    create(params) {
        return axios.post(`${this.getUrl()}/create`, params)
    }

    update(id, params) {
        return axios.post(`${this.getUrl()}/${id}/update`, params)
    }

    delete(id, params) {
        return axios.post(`${this.getUrl()}${id ? `/${id}` : ''}/delete`, params)
    }
}
