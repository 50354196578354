import axios from 'axios'
import ability from '@utils/ability'
import RolesService from '@src/services/RolesService'
/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode'

export const state = {
    currentUser: getFromLocalStorage('auth.currentUser'),
    currentToken: getFromLocalStorage('auth.currentToken'),
    abilities: getFromLocalStorage('auth.abilities'),
    defaultDashboardRole: getFromLocalStorage('auth.defaultDashboardRole'),
    refreshTask: () => ({}),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveInLocalStorage('auth.currentUser', newValue)
    },
    SET_CURRENT_TOKEN(state, newValue) {
        state.currentToken = newValue
        saveInLocalStorage('auth.currentToken', newValue)
        setDefaultAuthHeaders(state)
    },
    SET_ABILITIES(state, newValue) {
        state.abilities = newValue
    },
    SET_REFRESH_TASK(state, newValue) {
        state.refreshTask = newValue
    },
    SET_DEFAULT_DASHBOARD_ROLE(state, newValue) {
        state.defaultDashboardRole = newValue
        saveInLocalStorage('auth.defaultDashboardRole', newValue)
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentToken
    },

    abilities(state) {
        return state.abilities
    },

    permissions(state) {
        if (state.currentUser) {
            return state.currentUser.roles[0].permissions
        }
        return false
    },

    roleName(state) {
        if (state.currentUser) {
            return state.currentUser.roles[0].name
        }
        return ''
    },

    isAdmin(state, getters) {
        return (
            getters.roleName === 'admin entidade' ||
            getters.roleName === 'super admin'
        )
    },

    isSuperAdmin(state, getters) {
        return getters.roleName === 'super admin'
    },

    isBusinessUnit(state, getters) {
        return getters.roleName === 'cliente'
    },

    isPromotor(state, getters) {
        return getters.roleName === 'promotor'
    },

    tenantId(state) {
        return state.currentUser ? state.currentUser.tenant_id : 0
    },

    userLoggedIn(state) {
        return state.currentUser ? state.currentUser : null
    },

    activeModules(state) {
        return {
            costOfServingModule: state.currentUser?.cost_of_serving_module,
            commercialModule: state.currentUser?.commercial_module
        }
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state, dispatch }) {
        setDefaultAuthHeaders(state)
    },
    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return new Promise((resolve, reject) => {
            axios
                .post('/login', {
                    email,
                    password,
                })
                .then((response) => {
                    const token = response.data.access_token
                    commit('SET_CURRENT_TOKEN', token)

                    const { exp } = jwt_decode(response.data.access_token)
                    saveInLocalStorage('expiration', exp)

                    commit('SET_CURRENT_USER', response.data.user)
                    const abilities = createAbilitiesMap(response.data.user.roles[0].permissions)
                    saveAbilities(abilities)

                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    // Logs out the current user.
    logOut({ commit }) {
        commit('SET_ABILITIES', null)
        commit('SET_CURRENT_TOKEN', null)
        commit('SET_CURRENT_USER', null)
        commit('SET_REFRESH_TASK', () => ({}))
        commit('SET_DEFAULT_DASHBOARD_ROLE', null)
        clearLocalStorage()
    },

    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return axios
            .post('/reset', {
                email,
            })
            .then((response) => {
                return response.data
            })
    },

    async getPermissions({ dispatch, commit }) {
        return new Promise((resolve, reject) => {
            if (state.abilities && state.currentUser) {
                saveAbilities(state.abilities)
                return resolve(true)
            }
            return axios
                .post('/me')
                .then(({ data: { data }, status }) => {
                    commit('SET_CURRENT_USER', data)
                    const abilities = createAbilitiesMap(
                        data.roles[0].permissions
                    )
                    saveAbilities(abilities)
                    return resolve(true)
                })
                .catch((error) => {
                    dispatch('logOut')
                    return reject(error)
                })
        })
    },
    updateUser({ dispatch, commit }, { user }) {
        user.roles = state.currentUser.roles
        user.tenant_id = state.currentUser.tenant_id
        commit('SET_CURRENT_USER', user)
        return user
    },

    async validate({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            if (!state.currentToken) {
                return reject(new Error('Invalid token'))
            }
            const now = Date.now() / 1000 // exp is represented in seconds since epoch
            const { iat } = jwt_decode(state.currentToken)

            if (now - iat >= 5400) {
                return reject(new Error('Expired Token'))
            }

            return axios
                .post('/refresh')
                .then((response) => {
                    commit('SET_CURRENT_TOKEN', response.data.access_token)
                    resolve(true)
                })
                .catch((error) => {
                    dispatch('logOut')
                    reject(error)
                })
        })
    },

    getDefaultDashboardRole({ commit, state, dispatch }) {
        RolesService.getRoleByName('promotor').then((response) => {
            if (response.data.data.length > 0) {
                commit('SET_DEFAULT_DASHBOARD_ROLE', response.data.data[0])
            }
        })
            .catch((error) => {
                return error
            })
    },
}

// ===
// Private helpers
// ===

function updateAbilities(abilities) {
    if (abilities) {
        ability.update(abilities)
    }
}

function saveAbilities(abilities) {
    saveInLocalStorage('auth.abilities', abilities)
    updateAbilities(abilities)
}

function createAbilitiesMap(permissions) {
    return (
        permissions.map(({ name }) => {
            const ability = name.split('_')
            return {
                action: ability[1],
                subject: ability[0],
            }
        }) || []
    )
}

function getFromLocalStorage(key) {
    const expiration = JSON.parse(window.localStorage.getItem('expiration'))
    if (expiration > new Date().getTime()) {
        clearLocalStorage()
    }
    return JSON.parse(window.localStorage.getItem(key))
}

function saveInLocalStorage(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}

function setDefaultAuthHeaders(state) {
    axios.defaults.headers.common.Authorization = state.currentToken
        ? 'Bearer ' + state.currentToken
        : ''
}

function clearLocalStorage() {
    localStorage.clear()
}
