export default {
    'en-US': {
        currency: {
            style: 'currency',
            currency: 'USD',
        },
    },
    'pt-BR': {
        currency: {
            style: 'currency',
            currency: 'BRL',
        },
        percent: {
            style: 'percent',
        },
    },
}
